import React, { forwardRef } from 'react';
import RbButton from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const Button = ({ type = 'button', taskRunning, onClick, className = 'btn-primary', children }) => (
  <RbButton type={type} size='' variant='' disabled={taskRunning} onClick={onClick} className={className}>
    {taskRunning ? <Spinner role='status' animation='border' size='sm' /> : children }
  </RbButton>
)

const PrevButton = ({ type = 'button', icon = 'long-arrow-left', taskRunning, onClick, className = 'btn-primary btn-sm', children }) => (
  <RbButton type={type} size='' variant='' disabled={taskRunning} onClick={onClick} className={className}>
    {taskRunning ? <Spinner role='status' animation='border' size='sm' /> : <span><FalIcon icon={icon} className='ml-2'/>{children}</span> }
  </RbButton>
)

const NextButton = ({ type = 'button', icon = 'long-arrow-right', taskRunning, onClick, className = 'btn-primary btn-sm', children }) => (
  <RbButton type={type} size='' variant='' disabled={taskRunning} onClick={onClick} className={className}>
    {taskRunning ? <Spinner role='status' animation='border' size='sm' /> : <span>{children}<FalIcon icon={icon} className='ml-2'/></span> }
  </RbButton>
)

const IconButton = ({ type = 'button', taskRunning, onClick, className = 'btn-icon-white', children }) => (
  <RbButton type={type} size='' variant='' disabled={taskRunning} onClick={onClick} className={className}>
    {taskRunning ? <Spinner role='status' animation='border' size='sm' /> : children }
  </RbButton>
)

const TextButton = ({ type = 'button', taskRunning, onClick, className = 'btn-text-white', children }) => (
  <RbButton type={type} size='' variant='' disabled={taskRunning} onClick={onClick} className={className}>
    {taskRunning ? <Spinner role='status' animation='border' size='sm' /> : children }
  </RbButton>
)

const TextPrevButton = ({ type = 'button', icon = 'long-arrow-left', taskRunning, onClick, className = 'btn-text-white', children }) => (
  <RbButton type={type} size='' variant='' disabled={taskRunning} onClick={onClick} className={className}>
    {taskRunning ? <Spinner role='status' animation='border' size='sm' /> : <span><FalIcon icon={icon} className='mr-2'/>{children}</span> }
  </RbButton>
)

const TextNextButton = ({ type = 'button', icon = 'long-arrow-right', taskRunning, onClick, className = 'btn-text-white', children }) => (
  <RbButton type={type} size='' variant='' disabled={taskRunning} onClick={onClick} className={className}>
    {taskRunning ? <Spinner role='status' animation='border' size='sm' /> : <span>{children}<FalIcon icon={icon} className='ml-2'/></span> }
  </RbButton>
)

const ButtonWrapper = forwardRef((props, ref) => (
  <RbButton ref={ref} size='' variant='' {...props}>
    {props.children}
  </RbButton>
))

export {
  Button,
  PrevButton,
  NextButton,
  IconButton,
  TextButton,
  TextPrevButton,
  TextNextButton,
  ButtonWrapper
}

// Docs
// https://react-bootstrap.github.io/components/buttons/
