const React = require('react');
const { StoreProvider } = require('@mile-hi-labs/react-data');
const { DarkProvider } = require('./src/contexts/dark-context');
const { NavProvider } = require('./src/contexts/nav-context');
const { ToastProvider } = require('./src/contexts/toast-context');

const apiDomain = process.env.SERVER_URL;

exports.wrapRootElement = ({ element }) => {
	return (
		<StoreProvider apiDomain={apiDomain}>
			<DarkProvider>
				<NavProvider>
					{element}
				</NavProvider>
			</DarkProvider>
		</StoreProvider>
	)
}

exports.wrapPageElement = ({ element }) => {
	return (
		<ToastProvider>
			{element}
		</ToastProvider>
	)
}

//
// Docs
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
