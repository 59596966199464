import React, { useEffect, useState } from 'react';
import { Toast, ToastHeader, ToastBody } from 'src/interface/basics/toasts';
import { Fade } from 'src/interface/basics/animations';
import { IconButton } from 'src/interface/basics/buttons';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';
import { timeout } from 'src/utils/helpers';

const SuccessToast = ({ show, toast = {}, removeToast, children }) => {
  const [ animation, setAnimation ] = useState(null);


	// Render
	return (
    <Fade show={show} onEnter={() => setAnimation('fade-in-right')} onExit={() => setAnimation('fade-out-right')}>
      <Toast className={`success ${animation}`}>
        <ToastHeader>
          <span className='flex-start'>
        	 <FalIcon icon='check-circle'/>
        	 {toast.title && <h6 className='ml-2 mr-auto'>{toast.title}</h6>}
         </span>
         <IconButton onClick={() => removeToast()}><FalIcon icon='times'/></IconButton>
      	</ToastHeader>
        {toast.detail && <ToastBody className='p-3'>{toast.detail}</ToastBody>}
      </Toast>
    </Fade>
  )
}

export default SuccessToast;
