import React, { useEffect, useState } from 'react';
import LocalStorage from 'local-storage';

const ThemeContext = React.createContext();

const DarkProvider = (props) => {
	const { children } = props;
  const [ dark, setDark ] = useState(false);


  // Hooks
  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    console.log('darkMode: ', dark);
  }, [dark])


  // Methods
  function init() {
    let darkMode = LocalStorage.get('darkMode');
    setDark(darkMode ? darkMode : false);
  }

  function toggleDark(value) {
    value ? document.body.classList.add('dark') : document.body.classList.remove('dark');
    setDark(value);
  };


  // Render
  return (
    <ThemeContext.Provider value={{ dark, toggleDark }}>
      {children}
    </ThemeContext.Provider>
  );
};


const withDark = function(WrappedComponent) {
	return (props) => (
		<ThemeContext.Consumer>
			{context => <WrappedComponent darkContext={context} {...props} />}
		</ThemeContext.Consumer>
	)
}

export { DarkProvider, withDark }
