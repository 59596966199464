import React, { useEffect } from 'react';
import { Transition } from 'react-transition-group';

const Fade = (props) => {
	const { show, timeout = 300, onEnter, onEntering, onEntered, onExit, onExiting, onExited, children } = props;

	return (
		<Transition
			in={show}
			appear={show}
			timeout={timeout}
			onEnter={onEnter}
			onEntering={onEntering}
			onEntered={onEntered}
			onExit={onExit}
			onExiting={onExiting}
			onExited={onExited}
			unmountOnExit>
    	{children}
    </Transition>
	)
}

export { Fade }


// Docs
// https://reactcommunity.org/react-transition-group/transition
