import React from 'react';
import RbToast from 'react-bootstrap/Toast';

const ToastWrapper = ({ children }) => (
  <div className='toast-wrapper'>{children}</div>
)

const Toast = ({ onClose, className = '', children }) => (
  <RbToast show={true} onClose={onClose} className={className} >
    {children}
  </RbToast>
)

const ToastHeader = ({ className = '', children }) => (
  <RbToast.Header closeButton={false} className={className}>
    {children}
  </RbToast.Header>
)

const ToastBody = ({ className = '', children }) => (
  <RbToast.Body className={className}>
    {children}
  </RbToast.Body>
)

export { ToastWrapper, Toast, ToastHeader, ToastBody }

// Docs
// https://react-bootstrap.github.io/components/toasts/
