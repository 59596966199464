import React, { Fragment, useState } from 'react';
import { ToastWrapper } from 'src/interface/basics/toasts';
import SuccessToast from 'src/interface/toasts/success-toast';
import InfoToast from 'src/interface/toasts/info-toast';
import ErrorToast from 'src/interface/toasts/error-toast';
import { timeout, logger } from 'src/utils/helpers';

export const ToastContext = React.createContext();

const ToastProvider = (props) => {
  const { children } = props;
  const [ show, setShow ] = useState(false);
  const [ toast, setToast ] = useState({});


  // Methods
  function showSuccess(msg) {
    setToast({ type: 'success', status: 200, title: 'Success', detail: msg });
    setShow(true);
    removeToast(3000);
  }

  function showInfo(msg) {
    setToast({ type: 'info', status: 200, title: 'Info', detail: msg });
    setShow(true);
    removeToast(3000);
  }

  function showError(errors) {
    if (Array.isArray(errors)) {
      setToast({type: 'error', status: 422, title: 'Bad Request', detail: errors[0].detail });
    } else {
      setToast({ type: 'error', status: 422, title: 'App Error', detail: ' Sorry, there was an error.' });
    }
    setShow(true);
    removeToast(3000);
  }

  async function removeToast(time = 0) {
    await timeout(time);
    setShow(false);
  }


  // Render
  return (
    <ToastContext.Provider value={{showSuccess, showInfo, showError}}>
      <ToastWrapper>
        {toast.type == 'success' && <SuccessToast show={show} toast={toast} removeToast={() => removeToast()} />}
        {toast.type == 'info' && <InfoToast show={show} toast={toast} removeToast={() => removeToast()} />}
        {toast.type == 'error' && <ErrorToast show={show} toast={toast} removeToast={() => removeToast()} />}
      </ToastWrapper>
      {children}
    </ToastContext.Provider>
  )
}


const withToast = function(WrappedComponent) {
  return (props) => (
    <ToastContext.Consumer>
      {context => <WrappedComponent toast={context} {...props}/>}
    </ToastContext.Consumer>
  )
}

export { ToastProvider, withToast }

