import React from 'react';

const FabIcon = ({ icon = '', size = '1x', className = '' }) => (
  <i className={`fab fa-${icon} fa-${size} ${className}`}></i>
)

const FadIcon = ({ icon = '', size = '1x', className = '' }) => (
  <i className={`fad fa-${icon} fa-${size} ${className}`}></i>
)

const FalIcon = ({ icon = '', size = '1x', className = '' }) => (
  <i className={`fal fa-${icon} fa-${size} ${className}`}></i>
)

export { FabIcon, FadIcon, FalIcon }

// Docs
// https://fontawesome.com/kits/95c629a819/use
// https://fontawesome.com/how-to-use/on-the-web/styling/rotating-icons
