import React, { useEffect, useState } from 'react';

const NavContext = React.createContext();

const NavProvider = (props) => {
	const { children } = props;
  const [ nav, setNav ] = useState(false);


  // Hooks
  useEffect(() => {
    console.log('navOpen: ', nav);
  }, [nav])


  // Methods
  function toggleNav(value) {
    value ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
    setNav(value);
  };


  // Render
  return (
    <NavContext.Provider value={{ nav, toggleNav }}>
      {children}
    </NavContext.Provider>
  );
};


const withNav = function(WrappedComponent) {
	return (props) => (
		<NavContext.Consumer>
			{context => <WrappedComponent navContext={context} {...props} />}
		</NavContext.Consumer>
	)
}

export { NavProvider, withNav }
